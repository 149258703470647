
@import url(//fonts.googleapis.com/css?family=Prompt);


/* ======================================================================================= THEME 1 ======================================================================================= */

*{
    font-family: "Prompt", sans-serif !important;
}

body {
    font-family: "Prompt", sans-serif !important;
}


.document_static{
    font-size: 16px;
}

.ant-statistic-content{
    font-size: 16px;
}

.ant-layout-sider{
    position: relative !important;
    max-width   : 245px !important;
    min-width   : 245px !important;
    width       : 245px !important;
}

.ant-layout-sider-collapsed {
    position: relative !important;
    max-width   : 80px !important;
    min-width   : 80px !important;
    width       : 80px !important;
}

@media (max-width: 500px) {
    
    .ant-layout-sider-collapsed {
        position: relative !important;
        max-width   : 0px !important;
        min-width   : 0px !important;
        width       : 0px !important;
    }
}



.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
    color       : #fff;
}

.pepleCard{
    background  : #001529 !important;
    color       : #fff !important;
}

.pepleCard .wcolor{
    color: #fff;
}

.personCard{
    border : 1px soLid #5CC8A5;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0px;
}

.ant-form-item-label > label {
    font-weight: bold;
}

.ant-page-header {

    border-radius: 6px;
}

/* @media (max-width: 600px) {
    .ant-page-header {
        padding: 0px 0px;
        border-radius: 6px;
    }
  } */

.header-search .ant-select-item-group,
.header-search .ant-select-item-option-grouped {
    box-shadow: 0px -1px 0px #f2f2f2, 0px 1px 0px #f2f2f2;
}

.header-search .ant-select-item-option-grouped {
    padding: 10px;
}

.header-search .ant-select-item-group {
    padding: 16px;
}

.header-search .ant-select-item-option-content {
    white-space: unset;
}

#stock-number .ant-input-number-handler-wrap {
    opacity: 1;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 0;
}

.ant-card-meta-title
{
    color: #40495F;
    font-weight: 600;
}

.ant-menu-sub.ant-menu-inline {
    background: transparent;
}

.ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    transition: unset;
}

.ant-menu-submenu .ant-menu-sub {
    transition: unset;
}

.ant-menu-item .ant-menu-item-icon + span, .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span {
    transition: unset;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    transition: unset;
}

.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
    transition: unset;
}

.ant-menu-item, .ant-menu-submenu-title {
    transition: unset;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: -20px !important;
}

.ant-menu-submenu, .ant-menu-submenu-inline {
    transition: unset;
}

.ant-layout-content{
    background: #fff;
    padding: 30px 20px 30px;
}

.ant-layout-content .ant-page-header, .ant-layout-content .ant-card
{
    background: transparent;
}

.ant-card-actions {
    border-top: 0;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.ant-input {
    border-radius: 5px;
}

.ant-picker {
    border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-badge-count {
    background: #ff4d4f !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 14px;
    font-weight: 500;
}

.ant-tabs-tab-btn {
    letter-spacing: 0.5px;
}

.title_panel
{
    font-size: 0.7vw;
    color: #fff;
    background-color: #e2ce9d ;
    padding: 1.5% 3% 1.5%;
    border-radius: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.title_information
{
    font-size: 0.7vw;
    border-radius: 30px;
    padding: 1.5% 3% 1.5%;
    border: 1px soLid rgba(0, 0, 0, 0.65);
}

.content-sq_title-f
{
    font-size: 0.7vw;
    color: #D6C6A0;
    border: 2px soLid #D6C6A0 ;
    padding: 0.5% 3% 0.5%;
    border-radius: 30px;
    font-weight: 600;
    
    letter-spacing: 1px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.content-sq_title
{
    font-size: 0.7vw;
    color: #ACACAC;
    border: 2px soLid #ACACAC;
    padding: 0.5% 3% 0.5%;
    border-radius: 30px;
    font-weight: 600;
    letter-spacing: 1px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td
{
    border: 0
}

.ant-table-thead > tr > th {
    color: #fff;
    background: #234164;
    border: unset
}

.ant-table-column-sorter
{
    color: #fff !important;
    z-index: 1;
}

/* .ant-table-column-sorter-up, .ant-table-column-sorter-down {
    font-size: 11px;
} */

.ant-table-column-sorters:hover .ant-table-column-sorter {
    color: #a6a6a6;
    /* color: #234164; */
    background: #234164;
}

.ant-table-column-sorters::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #234164;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
    background: rgb(64, 73, 95);
    border-bottom: 1px soLid #f0f0f0;
    border-right: 1px soLid #f0f0f0;
}

.ant-table-content
{
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.ant-table-thead
{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.ant-page-header-heading-title
{
    color: rgb(64, 73, 95);
}

.ant-page-header-back-button
{
    color: rgb(64, 73, 95);
}

.ant-table.ant-table-bordered > .ant-table-container {
    border-left: 0;
}

#logo_sider img
{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ant-form-item .ant-upload.ant-upload-drag
{
    background-color: rgb(64, 73, 95);
    border: 0;
}

.ant-form-item .ant-upload.ant-upload-drag:hover
{
    background-color: rgb(75, 85, 110);
}

.ant-form-item .ant-upload.ant-upload-drag:hover p
{
    letter-spacing: 0.2px;
}

.ant-form-item .ant-upload.ant-upload-drag p
{
    color: #fff !important;
}

.radio-approve-true
{
    border-radius: 5px !important;
}

.radio-approve-false
{
    border-radius: 5px !important;
}

.radio-approve-true:hover
{
    
    color: #fff !important;
    background-color: #5CC8A5;
}

.radio-approve-false:hover
{
    color: #fff !important;
    background-color: #E42217;
}

.id-userprofile span
{
    padding: 1%;
    color: #fff !important;
    background: #D6C6A0;
    font-size: 0.8vw;
}

.status_user_show span
{
    padding: 0.6%;
    font-size: 0.8vw;
    color: #389e0d;
    background: #f6ffed;
    border: 1px soLid #b7eb8f;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.status_user_show_n span
{
    font-size: 0.8vw;
    color: #cf1322;
    background: #fff1f0;
    border: 1px soLid #ffa39e;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px soLid #d9d9d9;
}

.ant-collapse {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 13px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    background-color: #fff;
    border: 1px soLid #d9d9d9;
    border-bottom: 0;
    border-radius: 5px;
}

.cont{
    position:relative;
    overflow:hidden;
    height:250px;
    width:100%;
}
.s{
    overflow-y:scroll;
    height:350px;
    width:100%;
}

.ant-menu-light .ant-menu-item, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-title {
    color: #fff;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #fff;
}

.ant-menu
{
    background: transparent;
}

#card-sider 
{
    background: transparent;
    border: unset;
}

#card-sider .ant-card-meta-description
{
    color: #BBBBBB;
    text-transform: uppercase;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    background-color: #818693;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header span{
    color: #fff;
}

.ant-radio-button-wrapper-checked
{
    background-color: rgb(64, 73, 95);
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):first-child {
    border-radius: 5px 0px 0px 5px;
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):last-child{
    border-radius: 0px 5px 5px 0px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    background-color: rgb(64, 73, 95);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):last-child {
    background-color: rgb(64, 73, 95);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child span {
    color: #fff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):last-child span {
    color: #fff;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: unset;
}

.ant-btn-link {
    color: #40495F;
}

.ant-btn-link:hover, .ant-btn-link:focus {
    color: #0E1B32;
}

.ant-select-multiple .ant-select-selection-item 
{
    background: rgb(64, 73, 95);
    border-radius: 10px;
    border: 0px;
    color: #fff;
}

.ant-select-multiple .ant-select-selection-item-remove
{
    color: #fff;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff;
    font-weight: 600;
    background-color: rgb(64, 73, 95);
    border-radius: 5px;
}

.tag_post
{
    border-radius: 10px;
    background: rgb(226, 206, 157);
    margin-bottom: 3%;
}

.tag_post span
{
    color: #fff;
}

.tag_pet
{
    border-radius: 10px;
    background: rgb(173, 255, 203);
    margin-bottom: 3%;
}

.tag_pet span
{
    color: #fff;
}


.slide-image
{
    float:left;
    width:100%;
    height:100%;
    object-fit:cover;
}

.link_custom:hover
{
    color: #D6C6A0;
}

.ant-carousel .slick-dots li button {
    border-radius: 10px;
}

.header_custom {
    color: rgb(255, 255, 255);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-image: linear-gradient(90deg, rgba(229,229,233,1) 94%, rgba(214,198,160,1) 100%);
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: sticky;
    z-index: 15;
    top: 0px;
    left: auto;
    right: 0px;

    align-items: center;
    justify-content: flex-end;
    padding: 0px 24px;
    height: 64px;
}
/* .header_custom_mobile {
    color: rgb(255, 255, 255);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-image: linear-gradient(90deg, rgba(229,229,233,1) 94%, rgba(214,198,160,1) 100%);
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
    position: sticky;
    z-index: 15;
    top: 0px;
    left: auto;
    right: 0px;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 24px;
    height: 64px;
} */

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected {
    color: #40495f;
    background-color: #E5E5E9;
}

.ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: #000;
    font-size: 0.7vw;
    font-weight: 500;
}

.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 10px;
}

.ant-upload-list-picture .ant-upload-list-item a, .ant-upload-list-picture-card .ant-upload-list-item a {
    color : rgb(64, 73, 95) !important;
}

.ant-radio-wrapper {
    font-size: 0.7vw !important;
}

.ant-tag-green {
    padding: 1% 7% 1%;
}

.ant-tag-processing {
    color: #fff;
    padding: 1% 7% 1%;
}

.ant-switch-checked
{
    background: #0A58ED;
}

.descCut
{
    /* overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: nowrap;
    width: 30px; */

    white-space: nowrap; 
    width: 90%; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #40495f;
    color: #fff !important;
}

.ant-modal-content {
    border-radius: 10px;
}

.ant-radio-button-wrapper {
    font-size: 0.7vw !important;
}

.ant-radio-button-wrapper {
    padding: 0 0.5vw !important;
}


.disable_req label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    content: none !important;
}

.disable_req_c label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    content: none !important;
}

.disable_req_c > .ant-form-item-control > .ant-form-item-explain{
    display: flex;
    justify-content: center;
}

.ant-form-item-explain-error{
    float: left !important;
}

.input_disable_onshow .ant-input[disabled]{
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.65) !important;
}

.input_disable_onshow .ant-radio-disabled + span {
    color: rgba(0, 0, 0, 0.65) !important;
}

.input_disable_onshow .ant-radio-disabled + span {
    color: rgba(0, 0, 0, 0.65) !important;
}

.input_disable_onshow .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.65) !important;
    background-color: #fff !important;
}

.input_disable_onshow .ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.65) !important;
    background-color: #fff !important;
}

.input_disable_onshow .ant-picker.ant-picker-disabled {
    background: #fff !important;
}

.input_disable_onshow .ant-picker.ant-picker-disabled > .ant-picker-input > input {
    color: rgba(0, 0, 0, 0.65) !important;
}

.ant-descriptions-title {
    font-weight: 100;
    font-size: 13px;
}

.collapse_custom_view > .ant-collapse-item > .ant-collapse-header {
    background-color: #ECECEC;
}

.collapse_custom_view > .ant-collapse-item > .ant-collapse-header span {
    color: rgba(0, 0, 0, 0.65);;
}

#float_btn {
    box-sizing: border-box;
    margin: 0;
    /* padding: 0 0 0 4px; */
    color: rgba(0,0,0,.88);
    font-size: 16px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
    border: none;
    position: fixed;
    /* // cursor: pointer; */
    overflow: hidden;
    z-index: 99;
    display: block;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    display: flex;
    inset-inline-end: 10px;
    inset-block-end: 60px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    border: 1px solid rgba(0,0,0,.88);
    border-radius: 50px;
    background: #fff;
    transition-duration: 0.3s;
    cursor: pointer;
}
/* #red_dot {
    font-size: 16px;
    bottom: 0 !important;
    right: 0 !important;
} */

#float_btn:hover {
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
    background: #A7DB65;
    border: 1px solid rgba(0,0,0,0.2);;
    color: #fff;
}

#float_btn_login {
    box-sizing: border-box;
    margin: 0;
    /* padding: 0 0 0 4px; */
    color: rgba(0,0,0,.88);
    font-size: 16px;
    line-height: 1.5714285714285714;
    list-style: none;
    /* font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji; */
    border: none;
    position: fixed;
    /* // cursor: pointer; */
    overflow: hidden;
    z-index: 99;
    display: block;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    display: flex;
    inset-inline-end: 10px;
    inset-block-end: 60px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    border: 1px solid rgba(0,0,0,.88);
    border-radius: 50px;
    background: #fff;
    transition-duration: 0.3s;
    cursor: pointer;
}

#float_btn_login:hover {
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
    background: #0079d6;
    border: 1px solid rgba(0,0,0,0.2);;
    color: #fff;
}

.card {
    width: 250px;
    height: 200px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
  }
  
.card.selected {
    background-color: #3498db;
    color: white;
}

.custom_modal_chat > .ant-modal-content > .ant-modal-body {
    /* padding: unset; */
    padding: 15px 10px;
    background-color: #eaeaea;
}

.custom_search_chat {
    background-color: #3596ff;
    color: #fff;
    border: unset;
    border-radius: 10px;
}

.custom_search_chat > input {
    background-color: #3596ff;
    color: #fff;
}

.custom_search_chat > input::placeholder {
    color: #fff;
}

.custom_input_chat > .ant-input {
    background: transparent;
    border: unset;
}

.custom_input_chat > .ant-input:hover, .custom_input_chat > .ant-input:focus, .custom_input_chat > .ant-input:active {
    border: unset;
}

.ant-table-content::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}

.ant-table-content::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
}

/* Handle */
.ant-table-content::-webkit-scrollbar-thumb {
    background: #cfcfcf;
    border-radius: 10px;
}

.scrolling::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.scrolling::-webkit-scrollbar-track {
    background: #6C6C6C;
    border-radius: 10px;
}

/* Handle */
.scrolling::-webkit-scrollbar-thumb {
    background: #91d5ff;
    border-radius: 10px;
}

/* Handle on hover */
.scrolling::-webkit-scrollbar-thumb:hover {
    background: #95d5fc; 
}

.scrolling > div:last-child {
    scroll-snap-align: start;
}


/* ======================================================================== */

.scrolling_chat {
    overflow-y: auto;
    /* overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory; */

    display: flex;
    flex-direction: column-reverse;

    background-color: #fff;
    padding: 1rem;
}

.scrolling_table > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.scrolling_table > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-body::-webkit-scrollbar-track {
    background: #6C6C6C;
    border-radius: 10px;
}

/* Handle */
.scrolling_table > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container > .ant-table-body::-webkit-scrollbar-thumb {
    background: #91d5ff;
    border-radius: 10px;
}

.scrolling_chat::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.scrolling_chat::-webkit-scrollbar-track {
    background: #6C6C6C;
    border-radius: 10px;
}

/* Handle */
.scrolling_chat::-webkit-scrollbar-thumb {
    background: #91d5ff;
    border-radius: 10px;
}

/* Handle on hover */
.scrolling_chat::-webkit-scrollbar-thumb:hover {
    background: #95d5fc; 
}

.scrolling_chat > div:last-child {
    scroll-snap-type: y proximity;
    scroll-snap-align: start;
}

.h_short_msg {
    white-space: nowrap; 
    width: 90%; 
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.short_msg {
    white-space: nowrap; 
    width: 90%; 
    overflow: hidden;
    text-overflow: ellipsis;
    color: #C4C4C4;
    margin-top: 0.2rem;
}

/* .chat_msg_detail {
    scroll-snap-align: end;
} */

.card_chat {
    height: 60px;
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.card_chat:hover {
    background-color: #EBEBEB;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.upload_custom_i {
    background-color: transparent !important;
    height: 2rem !important;
}

.custom_collapse_i > .ant-collapse-item > .ant-collapse-header {
    background-color: #fff;
}

.custom_collapse_i > .ant-collapse-item > .ant-collapse-header span {
    color: #000;
}

#create_btn {
    border-radius: 5px;
    background-color: #1D304C;
    color: rgb(255, 255, 255);
}

#create_btn[disabled] {
    border-radius: 5px;
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
}

.ant-breadcrumb-link {
    color: #d5d5d5;
}

.last_breadcrumb {
    color: #1D304C;
    font-weight: 500;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: var(--ck-color-base-border);
    max-height: 400px !important;
    overflow-y: auto !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    background: var(--ck-color-base-background);
    border-radius: 0;
    max-height: 400px !important;
    overflow-y: auto !important;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
}

.dot_noti {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: red;
    animation: blinker 1s linear infinite;
    position: 'fixed';
    bottom: 85;
    right: 8; 
    z-index: 100;
    overflow:'hidden';
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}

.draw_cus > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-body {
    padding: 12px 0 0 0 !important;
}

.rounded-table .ant-table-wrapper {
    border-radius: 10px;
    overflow: hidden;
  }
  
.rounded-table .ant-table-thead > tr > th:first-child,
.rounded-table .ant-table-tbody > tr > td:first-child {
border-radius: 10px 0 0 10px;
}
  
.rounded-table .ant-table-thead > tr > th:last-child,
.rounded-table .ant-table-tbody > tr > td:last-child {
border-radius: 0 10px 10px 0;
}

.long-divider {
    width: 100% !important;
    height: 100% !important;
    color: #ff4d4f !important;
}

.divider-container {
    position: relative !important;
    z-index: 10 !important; /* Set the desired z-index value */
}
    
figure > img {
    width: auto;
    max-width: 100%;
}

.radio_custom_i_x_2 > .ant-radio {
    width: unset !important;
}

.radio_custom_i_x_2 > span {
    width: 100%;
}

.radio_custom_i_x_2 {
    width: 100%;
}

.chat-conversation .right .conversation-list .ctext-wrap {
    width: 75% !important;
    float: right !important;
    text-align: left !important;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-start !important;
}

.custom_disable_radio_i > .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: #fff;
    background-color: rgb(64, 73, 95);
    border-color: #40495f;
    box-shadow: none;
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-0 {
    background: rgba(225, 242, 255, 0.8);
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-0:hover > td {
    background: rgba(225, 242, 255, 1) !important;
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-0 > td {
    border-bottom: 1px solid #BBCCDE !important;
    color: #4B647E !important;
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-1 {
    background: rgba(237, 247, 255, 0.8);
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-1:hover > td {
    background: rgba(237, 247, 255, 1) !important;
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-1 > td {
    border-bottom: 1px solid #BBCCDE !important;
    color: #4B647E !important;
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-2 {
    background: rgba(245, 251, 255, 0.8);
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-2:hover > td {
    background: rgba(245, 251, 255, 0.6) !important;
}

#table_custom_hcu > .ant-table-container > .ant-table-content > table > tbody > .ant-table-row-level-2 > td {
    border-bottom: 1px solid #BBCCDE !important;
    color: #4B647E !important;
}

.bgimg {
    background-image: url("https://lscmain.s3.ap-southeast-1.amazonaws.com/no_inspector_2f0f260987.png");
    background-repeat:no-repeat;
}

.circular_image {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    background-color: blue;
    display:flex;
    vertical-align:middle;
  }

.ant-result-image {
    visibility: hidden;
}

.ant-result-title{
    visibility: hidden;
}

.scrolling_checklist::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.scrolling_checklist::-webkit-scrollbar-track {
    background: #E1E1E1;
    border-radius: 10px;
}

/* Handle */
.scrolling_checklist::-webkit-scrollbar-thumb {
    background: #91d5ff;
    border-radius: 10px;
}

/* Handle on hover */
.scrolling_checklist::-webkit-scrollbar-thumb:hover {
    background: #95d5fc; 
}

/* .ant-btn-primary{
    visibility: hidden;
} */


.tag_mjc_stat
{
    border-radius: 10px;
    background: #FFFBE6;
    margin-bottom: 3%;
}

.tag_mjc_stat span
{
    color: #FAAD14
    ;
}

.tag_mjc_stat_accept
{
    border-radius: 10px;
    background: #E6FFFB;
    margin-bottom: 3%;
}

.tag_mjc_stat_accept span
{
    color: #87E8DE;
}

.tag_mjc_stat_wait_appointment
{
    border-radius: 10px;
    background: #FFF0F6;
    margin-bottom: 3%;
}

.tag_mjc_stat_wait_appointment span
{
    color: #FFADD2;
}


.tag_mjc_stat_wait_inspect
{
    border-radius: 10px;
    background: #E6F7FF;
    margin-bottom: 3%;
}

.tag_mjc_stat_wait_inspect span
{
    color: #91D5FF;
}

.tag_mjc_stat_inspect
{
    border-radius: 10px;
    background: #F0F5FF;
    margin-bottom: 3%;
}

.tag_mjc_stat_inspect span
{
    color: #ADC6FF;
}

.tag_mjc_stat_inspect_over
{
    border-radius: 10px;
    background: #F9F0FF;
    margin-bottom: 3%;
}

.tag_mjc_stat_inspect_over span
{
    color: #D3ADF7;
}

.tag_mjc_stat_wait_apprv
{
    border-radius: 10px;
    background: #FFF2E8;
    margin-bottom: 3%;
}

.tag_mjc_stat_wait_apprv span
{
    color: #FFBB96;
}



.tag_pet_green
{
    border-radius: 10px;
    background: #F6FFED;
    margin-bottom: 3%;
    border-color: #4d944f;
}

.tag_pet_green span
{
    color: #4d944f;
}

.tag_pet_blue
{
    border-radius: 10px;
    background: #b1d8ff;
    margin-bottom: 3%;
    border-color: #4c7ed4;
}

.tag_pet_blue span
{
    color: #4c7ed4;
}

.tag_pet_pink
{
    border-radius: 10px;
    background: #fea2e0;
    margin-bottom: 3%;
    border-color: #a84386;
}

.tag_pet_pink span
{
    color: #a84386;
}

.tag_pet_orange
{
    border-radius: 10px;
    background: #ffdf87;
    margin-bottom: 3%;
    border-color: #f59f27;
}

.tag_pet_black
{
    border-radius: 10px;
    background: #9a9a9a;
    margin-bottom: 3%; 
    border-color: #262626;
}

.tag_pet_black span
{
    color: #262626;
}

.tag_pet_orange span
{
    color: #f59f27;
}

.tag_pet_red
{
    border-radius: 10px;
    background: #ffc2c2;
    margin-bottom: 3%;
    border-color: #ff2525;
}

.tag_pet_red span
{
    color: #ff2525;
}



.tag_mjc_stat_done
{
    border-radius: 10px;
    background: #F6FFED;
    margin-bottom: 3%;
}

.tag_mjc_stat_done span
{
    color: #B7EB8F;
}

.tag_mjc_stat_done_expire
{
    border-radius: 10px;
    background: #FFF1F0;
    margin-bottom: 3%;
}

.tag_mjc_stat_done_expire span
{
    color: #FFA39E;
}

.ant-picker-mjc {
    border-radius: 5px;
    width: 100%;
}

.div_img_checklist {
    backdrop-filter: blur(20);
}

.ck-read-only{
    background-color: #1D304C;
}

.card_custom_andlist > .ant-card-body {
    padding: 10px !important;
}

/* .ck-blurred .ck .ck-content .ck-editor__editable .ck-rounded-corners .ck-editor__editable_inline .ck-read-only{
    background: #d00000 !important;
    background-color: #d00000 !important;
} */

.ck .ck-editor__main > .ck-editor__editable.ck-read-only {
    background: gainsboro;
}

.custom_header_tool_in_template_1 {
    width: 64%;
}
.custom_header_tool_in_template_2 {
    width: 12%;
}
.custom_header_tool_in_template_3 {
    width: 11%;
}
.custom_header_tool_in_template_4 {
    width: 13%;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.card-custom-selected {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(0, 126, 214) 0px 0px 0px 3px !important;
    transition: 0.3s;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.ant-radio-wrapper-checked > span > .ant-form-item {
    display: inline-block;
    margin-left: 2rem;
    transform: translate(0, -5px);
    margin-bottom: unset !important;
    position: absolute;
    width: 300px;
}

@media screen and (max-width: 1710px) {
    .custom_header_tool_in_template_1 {
        width: 60%;
    }
    .custom_header_tool_in_template_2 {
        width: 15%;
    }
    .custom_header_tool_in_template_3 {
        width: 10%;
    }
    .custom_header_tool_in_template_4 {
        width: 15%;
    }
}

@media screen and (max-width: 1510px) {
    .custom_header_tool_in_template_1 {
        width: 56%;
    }
    .custom_header_tool_in_template_2 {
        width: 17%;
    }
    .custom_header_tool_in_template_3 {
        width: 10%;
    }
    .custom_header_tool_in_template_4 {
        width: 17%;
    }
}

@media screen and (max-width: 1400px) {
    .custom_header_tool_in_template_1 {
        width: 53%;
    }
    .custom_header_tool_in_template_2 {
        width: 17%;
    }
    .custom_header_tool_in_template_3 {
        width: 10%;
    }
    .custom_header_tool_in_template_4 {
        width: 20%;
    }
}

@media screen and (max-width: 1300px) {
    .custom_header_tool_in_template_1 {
        width: 50%;
    }
    .custom_header_tool_in_template_2 {
        width: 20%;
    }
    .custom_header_tool_in_template_3 {
        width: 10%;
    }
    .custom_header_tool_in_template_4 {
        width: 20%;
    }
}

/* .animated-tag {
    border-radius: 10px;
    box-shadow: rgba(0, 255, 50, 0.6) 0px 0px 40px;
    width: 60%;
    color: #1D304C;
    transition: all 0.3s ease;
  }
  
  .animated-tag:hover {
    transform: scale(1.1);
    box-shadow: rgba(0, 255, 50, 0.8) 0px 0px 40px;
  }
  
  .animated-tag:active {
    transform: scale(0.9);
  }   */

  @keyframes rainbow-grow {
    0% {
      width: 0%;
    }
    100% {
      width: 60%;
    }
  }
  
  .animated-tag {
    position: relative;
    overflow: hidden;
    width: 0%;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    animation: rainbow-grow 1s ease forwards;
  }
  
  .animated-tag::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      #ff0000,
      #ff7f00,
      #ffff00,
      #00ff00,
      #0000ff,
      #8a2be2
    );
    animation: rainbow 3s linear infinite;
  }
  
  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
.ant-upload.ant-upload-disabled {
    cursor: not-allowed;
    visibility: hidden;
    margin-top: -10%;
}

.background-landing-k {
    position: relative;
    width: 100%;
    height: 1000px;
    display: inline;
    background-image: url(https://lscmain.s3.ap-southeast-1.amazonaws.com/lsc_landing_img_9e823858e4.png);
}

.ant-input[disabled] {
    color: rgb(16 15 15);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td, .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
    border: 0;
    z-index: 1;
}

.e_stamp_layout_res {
    width: 50%;
    margin: 0 auto;
}

.span-e-stamp-res {
    font-size: 12px;
    margin: 0 8px;
}

.none_cell > .ant-form-item-label {
    display: none !important;
}

.visible-label > .ant-form-item-label {
    display: none !important;
}

.card_custom_pet_species > .ant-card-body {
    padding: 10px;
}

.req_custom {
    font-family: SimSun, sans-serif !important;
}

.ant-table-tbody .table_colmn_custom_left_value {
    text-align: left !important;
}

@media screen and (max-width: 1300px) {
    .e_stamp_layout_res {
        width: 60%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1000px) {
    .e_stamp_layout_res {
        width: 80%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 600px) {
    .span-e-stamp-res {
        font-size: 12px;
        margin: 0 5px;
    }

    .e_stamp_layout_res {
        width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 500px) {
    .span-e-stamp-res {
        font-size: 10px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 400px) {
    .span-e-stamp-res {
        font-size: 10px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 300px) {
    .span-e-stamp-res {
        font-size: 10px;
        margin: 0 5px;
    }
}