#logo_life {
    background-image: url(../../../../public/images/login/logo_news.svg);
    width: 4.3vw;
    height: 5.8vw;
    display: block;
    margin: 0 auto;
    background-size: contain;
}

#bg_web {
    background-image: url(../../../../public/images/login/bg_news.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: fixed; 
    top: 0; 
    left: 0; 

    min-width: 100%;
    min-height: 100%;
    display: flex;
}

.logo_cus_i {
    width: 120px;
}

.col_1_cus_i {
    width: 55%;
}

.col_2_cus_i {
    display: flex;
    justify-content: center;
    width: 45%;
}

.col_3_cus_i {
    width: 57%;
}

.col_4_cus_i {
    width: 43%;
}

#btn_forgotpwd {
    background: transparent;
    border: 0;
    height: 48px;
}

#btn_forgotpwd span {
    color: #fff;
    font-weight: 300;
    font-size: 15px;
}

#btn_signin {
    background: #00FF32;
    border-radius: 10px;
    border: 0;
    height: 48px;
}

#btn_signin:hover {
    box-shadow: rgba(0, 255, 50, 0.5) 0px 0px 24px;
    letter-spacing: 1px;
}

#btn_signin span {
    color: #234164;
    font-weight: 300;
    font-size: 20px;
}

#form_logi {
    text-align: center;
    width: 66%;
    height: 90%;
    background: rgba(18, 33, 50, 0.65);
    box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7px);
    border-radius: 0 0 25px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#form_logi > .ant-card-body {
    width: 90%;
}

#input_logi {
    background-color: #1D2F43;
    border: 1px solid #3274A5;
    color: #fff;
}

.cus_input_pwd > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content > .ant-input-password {
    background-color: #1D2F43;
    border: 1px solid #3274A5;
}

#input_logi_pwd {
    background-color: #1D2F43;
    color: #fff;
}

.font_size_30_cus_i {
    font-size: 30px;
}

.font_size_15_cus_i {
    font-size: 15px;
}

.font_size_12_cus_i {
    font-size: 12px;
}

.icn_forgotpwd {
    width: 30px;
    height: 30px;
}

@media (max-width: 1500px) {
    #bg_web
    {
        background-image: url(../../../../public/images/login/bg_news.svg);
        background-size: cover;
        background-repeat: no-repeat;

        position: fixed; 
        top: 0; 
        left: 0; 

        min-width: 100%;
        min-height: 100%;
        height: 100vh;
        display: flex;
    }

    .col_1_cus_i {
        width: 55%;
    }
    
    .col_2_cus_i {
        display: flex;
        justify-content: center;
        width: 45%;
    }

    #form_logi {
        text-align: center;
        width: 80%;
        height: 90%;
        background: rgba(18, 33, 50, 0.65);
        box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7px);
        border-radius: 0 0 25px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1350px) {
    #bg_web
    {
        background-image: url(../../../../public/images/login/bg_news.svg);
        background-size: cover;
        background-repeat: no-repeat;

        position: fixed; 
        top: 0; 
        left: 0; 

        min-width: 100%;
        min-height: 100%;
        height: 100vh;
        display: flex;
    }

    .col_1_cus_i {
        width: 55%;
    }
    
    .col_2_cus_i {
        display: flex;
        justify-content: center;
        width: 45%;
    }

    #form_logi {
        text-align: center;
        width: 80%;
        height: 90%;
        background: rgba(18, 33, 50, 0.65);
        box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7px);
        border-radius: 0 0 25px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1250px) {
    #bg_web
    {
        background-image: url(../../../../public/images/login/bg_news.svg);
        background-size: cover;
        background-repeat: no-repeat;

        position: fixed; 
        top: 0; 
        left: 0; 

        min-width: 100%;
        min-height: 100%;
        height: 100vh;
        display: flex;
    }

    .col_1_cus_i {
        width: 50%;
    }
    
    .col_2_cus_i {
        display: flex;
        justify-content: center;
        width: 50%;
    }

    #form_logi {
        text-align: center;
        width: 90%;
        height: 90%;
        background: rgba(18, 33, 50, 0.65);
        box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7px);
        border-radius: 0 0 25px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1000px) {
    #bg_web
    {
        background-image: url(../../../../public/images/login/bg_news.svg);
        background-size: cover;
        background-repeat: no-repeat;

        position: fixed; 
        top: 0; 
        left: 0; 

        min-width: 100%;
        min-height: 100%;
        height: 100vh;
        display: flex;
    }

    .col_1_cus_i {
        width: 40%;
    }
    
    .col_2_cus_i {
        display: flex;
        justify-content: center;
        width: 60%;
    }

    #form_logi {
        text-align: center;
        width: 90%;
        height: 90%;
        background: rgba(18, 33, 50, 0.65);
        box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7px);
        border-radius: 0 0 25px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 800px) {
    #bg_web
    {
        background-image: url(../../../../public/images/login/bg_news.svg);
        background-size: cover;
        background-repeat: no-repeat;

        position: fixed; 
        top: 0; 
        left: 0; 

        min-width: 100%;
        min-height: 100%;
        height: 120vh;
        display: flex;
    }

    .col_1_cus_i {
        width: 0;
    }
    
    .col_2_cus_i {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    #form_logi
    {
        text-align: center;
        width: 70%;
        height: 80%;
        background: rgba(18, 33, 50, 0.65);
        box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7px);
        border-radius: 0 0 25px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media (max-width: 680px) {
    #bg_web
    {
        background-image: url(../../../../public/images/login/bg_news.svg);
        background-size: cover;
        background-repeat: no-repeat;

        position: fixed; 
        top: 0; 
        left: 0; 

        min-width: 100%;
        min-height: 100%;
        height: 120vh;
        display: flex;
    }

    .col_1_cus_i {
        width: 0;
    }
    
    .col_2_cus_i {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    #form_logi
    {
        text-align: center;
        width: 80%;
        height: 80%;
        background: rgba(18, 33, 50, 0.65);
        box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7px);
        border-radius: 0 0 25px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media (max-width: 600px) {
    #bg_web
    {
        background-image: url(../../../../public/images/login/bg_news.svg);
        background-size: cover;
        background-repeat: no-repeat;

        position: fixed; 
        top: 0; 
        left: 0; 

        min-width: 100%;
        min-height: 100%;
        height: 120vh;
        display: flex;
    }

    .col_1_cus_i {
        width: 0;
    }
    
    .col_2_cus_i {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .col_3_cus_i {
        width: 50%;
    }
    
    .col_4_cus_i {
        width: 50%;
    }

    #form_logi
    {
        text-align: center;
        width: 90%;
        height: 80%;
        background: rgba(18, 33, 50, 0.65);
        box-shadow: 0px 22px 40px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7px);
        border-radius: 0 0 25px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icn_forgotpwd {
        width: 25px;
        height: 25px;
    }

    .font_size_30_cus_i {
        font-size: 25px;
    }
    
    .font_size_15_cus_i {
        font-size: 12px;
    }
    
    .font_size_12_cus_i {
        font-size: 10px;
    }

    #btn_forgotpwd {
        background: transparent;
        border: 0;
        height: 40px;
    }
    
    #btn_forgotpwd span {
        color: #fff;
        font-weight: 300;
        font-size: 15px;
    }
    
    #btn_signin {
        background: #00FF32;
        border-radius: 10px;
        border: 0;
        height: 40px;
    }
    
    #btn_signin span {
        color: #234164;
        font-weight: 300;
        font-size: 15px;
    }
}

@media (max-width: 500px) {
    #btn_forgotpwd {
        background: transparent;
        border: 0;
        height: 40px;
    }
    
    #btn_forgotpwd span {
        color: #fff;
        font-weight: 300;
        font-size: 12px;
    }
    
    #btn_signin {
        background: #00FF32;
        border-radius: 10px;
        border: 0;
        height: 40px;
    }
    
    #btn_signin span {
        color: #234164;
        font-weight: 300;
        font-size: 15px;
    }

    .font_size_30_cus_i {
        font-size: 20px;
    }
    
    .font_size_15_cus_i {
        font-size: 12px;
    }
    
    .font_size_12_cus_i {
        font-size: 10px;
    }

    .col_3_cus_i {
        width: 100%;
    }
    
    .col_4_cus_i {
        width: 100%;
    }

    .logo_cus_i {
        width: 100px;
    }
}
