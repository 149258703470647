.ant-page-header {

    border-radius: 6px;
}

@media (max-width: 600px) {
    .ant-page-header {
        padding: 0px 0px;
        border-radius: 6px;
    }

    .ant-form-item-label > label {
        font-size: 12px; /* Change this to your desired font size */
      }
  }


  @media (max-width: 1024px) {
    
    .ant-form-item-label > label {
        font-size: 14px; /* Change this to your desired font size */
      }
  } 