.dragger_upload_custom_i_w{
    border-radius: 10px !important;
    // width: 9vw !important;
    width: 95% !important; 
}

.dragger_upload_custom_i_w .ant-upload{
 padding: 0 !important;
}

.dragger_upload_custom_i_h{
    border-radius: 10px !important;
    width: 100% !important; 
    height: 16vw !important;
}

.dragger_upload_custom_i_m{
    border-radius: 10px !important;
    width: 100% !important; 
    height: 12.2vw !important;
}


.form_projectPix:hover .projectPix_hover {
    visibility: unset;
    background-color: rgba(0,0,0,0.5046393557422969);
}
  
.projectPix_hover {
    transition: background-color 0.3s ease-out;
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    visibility: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectPix > img {
    object-fit: cover !important;
}

.icons_upload {
    color: #fff;
    font-size: 22px;
}