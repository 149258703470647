.ant-layout-sider-children
{
    // background: linear-gradient(0deg, rgba(43,51,71,1) 0%, rgba(64,73,95,1) 73%);
    background-color: #1D304C;
    box-shadow: rgb(50 50 93 / 15%) 0px 2px 5px -1px, rgb(0 0 0 / 10%) 0px 1px 3px -1px;
}

.ant-menu-item-selected .ant-menu-tree-arrow {
    visibility: hidden;
}

.ant-menu-item-selected {
    background-color: #175947 !important;
    border-radius: 50px;
    color: #fff;
    width: 90%;
    margin: auto;
}

.ant-layout-sider > .ant-layout-sider-children > div > .ant-menu > .ant-menu-item-selected {
    background-color: #175947 !important;
    border-radius: 50px;
    color: #fff;
    width: 90%;
    margin: auto;
}

.ant-layout-sider-collapsed > .ant-layout-sider-children > div > .ant-menu > .ant-menu-item-selected > .ant-menu-item-icon {
    margin-left: 4px !important;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item, .ant-menu-vertical-left.ant-menu-sub .ant-menu-item, .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: auto !important;
    border-right: 0;
}

.ant-layout-sider-collapsed > .ant-layout-sider-children > div > .ant-menu > .ant-menu-item-selected {
    background-color: #175947 !important;
    border-radius: 50px;
    color: #fff;
    width: 10px;
    margin: auto;
    padding-left: 10px !important;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item, .ant-menu-vertical-left.ant-menu-sub .ant-menu-item, .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: auto !important;
    border-right: 0;
}

.ant-menu-submenu-popup > .ant-menu {
    background-color: #1D304C;
    padding: 0.5rem 0;
    border-radius: 5px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-selected .ant-menu-submenu-title:hover, .ant-menu-submenu-selected:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #fff !important;
}

.ant-menu-submenu-title:hover {
    color: #00FF32 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
    color: #175947;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-selected .ant-menu-submenu-title {
    background-color: #175947 !important;
    border-radius: 50px;
    color: #fff;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: transparent !important;
}

.ant-layout-sider > .ant-layout-sider-children > div > .ant-menu > .ant-menu-submenu-selected {
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
}

.ant-layout-sider-collapsed > .ant-layout-sider-children > div > .ant-menu > .ant-menu-submenu-selected {
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;
}

.ant-layout-sider > .ant-layout-sider-children > .sider_custom {
    position: fixed !important;
    z-index: 30;
    height: 95vh;
    overflow-y: auto;
    width: 245px;
    transition: 0.2s;
    /* top: 0px;
    left: auto;
    right: 0px; */
}

.ant-layout-sider-collapsed > .ant-layout-sider-children > .sider_custom {
    position: fixed !important;
    z-index: 30;
    height: 95vh;
    overflow-y: auto;
    width: 80px;
    transition: 0.2s;
    /* top: 0px;
    left: auto;
    right: 0px; */
}


@media (max-width: 500px) {


    .ant-layout-sider-collapsed > .ant-layout-sider-children > .sider_custom {
        position: fixed !important;
        z-index: 20;
        height: 95vh;
        overflow-y: auto;
        width: 0;
        transition: 0.2s;
        /* top: 0px;
        left: auto;
        right: 0px; */
    }
}




.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-selected .ant-menu-item  {
    padding-left: 35px !important;
}

.ant-menu-item:hover {
    color: #00FF32 !important;
}

.ant-menu-item-selected:hover {
    color: #fff !important;
}

.ant-layout-sider-trigger{
    width: 245px !important;
    background: #1D304C;
}

.ant-layout-sider-collapsed > .ant-layout-sider-trigger{ 
    width: 80px !important;
    background : #1D304C;
}

.sider_custom::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
.sider_custom::-webkit-scrollbar-track {
    background: #888;
    border-radius: 10px;
}

/* Handle */
.sider_custom::-webkit-scrollbar-thumb {
    background: #1C9750;
    border-radius: 10px;
}