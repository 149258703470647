h2 {text-align: center;}
.t1 {text-align: center;}
.Col {text-align: center;}
div.parent p { color:red; }
table.table_1 p {text-align: center;}
.t1 {text-align: center;}

.btn_custom_i_print{
    width: 250px;
    height: 40px;
    margin: 2% auto;
    border-radius: 5px;
    border: 1px solid #ddd;
    cursor: pointer;
    background-color: #BCFF87;
    text-align: center;
    font-size: 18px;
    color: #4EA420
}