body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.column {
    float: center;
    width: 50%;
    margin-bottom: 16px;
    padding: 0 8px;
}

.card_k {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
    width: 400px;
}

.container {
    padding: 0 16px;
}

.container::after, .row::after {
    content: "";
    clear: both;
    display: table;
}

.title {
    color: grey;
}

.button {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 8px;
    color: white;
    background-color: rgb(28, 81, 108);
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.button:hover {
    background-color: #555;
}

@media screen and (max-width: 650px) {
.column {
    width: 100%;
    display: block;
}
}