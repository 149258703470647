/* .ant-layout-sider-children
{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
} */

#userprofile_image
{
    background-color: #fff;
    padding-top: 15%;
    border-radius: 10px 0px 0px 10px;
}

#userprofile_n .ant-typography
{
    color: #fff;
}

.userprofile_mleft 
{
    margin-left: 5%;
}

.userprofile_mright .ant-typography
{
    font-size: 18px;
    color: #000;
}

.btn_msg_userprofile
{
    border-radius: 10px;
    width: 150px !important;
    background: linear-gradient(to top, #2c29f5 0%, #3c8af4 100%);
    color: #fff;
    border: 0px;
}

.btn_msg_userprofile:hover,
.btn_msg_userprofile:focus
{
    background: linear-gradient(to top, #2c29f5 0%, #3c8af4 100%);
    color: #fff;
}

.ant-card-bordered {
    border: 1px soLid #ffffff;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
}

.content_title{
    font-size: 1.5rem;
    font-weight: 500;
    color: #000;
}

.content{
    font-size: 1rem;
    font-weight: 500;
    color: #000;
}



/* .ant-layout .ant-card-body
{
    background-color: red !important;
} */

#block_container
{
    /* text-align:center; */
    margin-bottom: 2%;
}
#block1, #block2
{
    display:inline;
}

#block2 button
{
    border: 0px;
    border-radius: 10px;
    background: linear-gradient(to top, #3c8af4  0%, #3c8af4 100%);
    color: #fff;
    float: right;
}

#post_type_list
{
    /* background: linear-gradient(to top, #2c29f5 0%, #3c8af4 100%); */
    border: 1px soLid rgba(0, 0, 0, 0.65) !important;
    border-radius: 20px;
    border: 0px;
    padding: 3px 10px 3px;
    margin-bottom: 3%;
}

#post_type_list span
{
    /* color: #fff !important; */
}

#post_state_list
{
    background: #DCFDD4;
    border-radius: 20px;
    border: 0px;
    padding: 3px 10px 3px;
}

#post_state_list span
{
    color: #4FAC16 !important;
    margin: 5px;
    text-align: center;
}

/* .ant-menu.ant-menu-inline-collapsed > .ant-space
{
    opacity: 0 !important;
} */